<template>
  <div class="th-login-pop" @click="closeLoginPop">
    <div class="th-login" @click.stop="openLoginPop">
      <div class="th-login-title">
        <h2>登录</h2>
        <span class="close icon-close" @click="closeLoginPop"></span>
      </div>
      <div class="th-login-from">
        <form>
          <ul>
            <li>
              <span class="icon icon-account"></span>
              <input
                type="text"
                v-model="from.account"
                class="input-wid"
                placeholder="请输入用户名"
                @input="checkFrom"
              />
            </li>
            <li>
              <span class="icon icon-lock"></span>
              <input
                type="Password"
                class="input-wid"
                v-model="from.pwd"
                placeholder="请输入登录密码"
                @input="checkFrom"
              />
            </li>
          </ul>
        </form>
        <div class="th-login-pwd">
          <label @click="isPwd = !isPwd">
            <span class="check" :class="{ hover: isPwd }"></span>记住密码
          </label>
        </div>
        <div class="th-login-link">
          <button
            class="th-link"
            :class="{ 'th-link-no': isLink }"
            @click="checkCodeFrom"
          >
            登录
          </button>
        </div>
        <div class="th-login-tips">
          <div class="left" @click="contactWeb">登录遇到问题？</div>
          <div class="right">
            没有账号？<span class="text-glod" @click="gotoRegister"
              >立即注册</span
            >
          </div>
        </div>
      </div>
    </div>
    <Slider
      v-if="show"
      :visible.sync="show"
      ref="sliderComponent"
      :fromValue="from"
      :log="true"
    ></Slider>
  </div>
</template>
<script>
import { setToken } from "@/utils/token";
import Slider from "@/components/Slider.vue";
import "@/utils/emptyVue";
export default {
  components: { Slider },
  data() {
    return {
      show: false,
      isPwd: true,
      from: {
        account: "",
        pwd: "",
      },
      isLink: true,
      codeImg: "",
      codeUUid: "",
    };
  },
  created() {
    this.$bus.$on("aevent", (params) => {
      this.validImg(params);
    });
  },
  mounted() {
    this.jzpwd();
  },
  methods: {
    // 记住密码
    jzpwd() {
      const isPwd = localStorage.getItem("loginInfo");
      if (isPwd) {
        const info = JSON.parse(isPwd);
        const { account, pwd } = info;
        this.from = {
          account,
          pwd,
        };
        this.isLink = false;
      }
    },
    checkCodeFrom() {
      if (!(this.from.account && this.from.pwd)) {
        this.$message({
          message: "请先填写登录信息",
          type: "warning",
        });
        return;
      }
      this.show = true;
    },
    gotoRegister() {
      this.openRegisterPop();
      this.closeLoginPop();
    },
    validImg(data) {
      this.codeUUid = data;
      this.loginSend();
    },
    loginSend() {
      const { account, pwd } = this.from;
      let params = {
        userName: account,
        password: pwd,
        captchaId: this.codeUUid,
      };
      this.$Api.loginSend(params).then((res) => {
        if (res && res.code === 0) {
          this.$store.dispatch("setHasLoginAc", true);
          setToken(res.token);
          const str = JSON.stringify(this.from);

          if (this.isPwd) {
            localStorage.setItem("loginInfo", str);
          } else {
            localStorage.removeItem("loginInfo");
          }
          // this.restFrom();
          this.closeLoginPop();
          return;
        }
      });
    },
    restFrom() {
      this.from = {
        account: "",
        pwd: "",
        code: "",
      };
    },
    checkFrom() {
      let isFrom = false;
      Object.keys(this.from).map((item) => {
        // 填写不为空，去除邀请码的验证
        if (!this.from[item]) {
          isFrom = true;
        }
      });

      this.isLink = isFrom;
    },
  },
};
</script>
<style lang="scss" scoped>
.th-login {
  width: 410px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  &-pop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 20;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #222222;
    height: 60px;
    color: #e2bf74;
    position: relative;
    font-size: 16px;
    border-radius: 8px 8px 0 0;
    h2 {
      font-weight: normal;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      color: white;
      opacity: 0.5;
      transition: all 0.4s;
      cursor: pointer;
      &:hover {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }
  &-link {
    width: 100%;
    .th-link {
      width: 100%;
    }
  }
  &-tips {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #999;
    .left {
      cursor: pointer;
    }
  }
  &-pwd {
    label {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 13px;
      color: #999;
      max-width: 120px;
      .check {
        width: 14px;
        height: 14px;
        background: #eee;
        border: 1px solid #dcdcdc;
        border-radius: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &.hover {
          background: var(--theme);
          border: 1px solid var(--theme);
          &::before {
            content: "";
            width: 3px;
            height: 6px;
            border: 2px solid white;
            transform: translateY(-1px) rotate(45deg);
            border-top: none;
            border-left: none;
          }
        }
      }
    }
  }
}
.th-login-from {
  display: flex;
  flex-direction: column;
  padding: 50px 60px;
  gap: 16px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  li {
    border: 1px solid #ebc58c;
    height: 40px;
    font-size: 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    .icon {
      text-align: center;
      font-size: 16px;
      color: var(--theme);
    }
    .input-wid {
      flex: 1;
      width: 100%;
      &::placeholder {
        color: #999;
      }
    }
    .imgCode {
      height: 30px;
      margin-right: 4px;
      cursor: pointer;
    }
  }
}

/deep/.input-wid {
  .el-input__inner {
    border: none;
    background: none;
  }
}
</style>
