<template>
  <div class="ht-right" v-if="baseInfo">
    <a :href="baseInfo[6].configValue" target="_black" class="row">
      <img src="@/assets/pic/index-cs.png" class="img" />
      客服中心</a
    >
    <div class="row row-app">
      <div class="img">
        <img src="@/assets/pic/download.png" />
      </div>
      APP下载
      <div class="row-download">
        <div class="item">
          <QRCode :text="baseText('iosApp')" :width="140" :height="140" />
          <a :href="baseInfo[22].configValue">{{ $t("download.sm") }}iOS</a>
        </div>
        <div class="item">
          <QRCode :text="baseText('androidApp')" :width="140" :height="140" />
          <a :href="baseInfo[21].configValue" target="_black"
            >{{ $t("download.sm") }}andriod</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "@/components/QRCode.vue";
export default {
  components: {
    QRCode,
  },
};
</script>
<style lang="scss" scoped>
.ht-right {
  border-radius: 10px 0px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f8f3e8 -0.03%, #e6d0aa 99.33%);
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  gap: 16px;
  padding: 16px 0;
  .row {
    width: 66px;
    cursor: pointer;
    position: relative;
    .img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 75%;
      }
    }
    &.row-app {
      &::before {
        content: "";
        position: absolute;
        left: -30px;
        width: 30px;
        top: 0;
        bottom: 0;
        background: transparent;
      }
      &:hover {
        .row-download {
          display: flex;
        }
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #000;
    font-size: 12px;
    .row-download {
      position: absolute;
      background: white;
      border-radius: 8px;
      padding: 10px;
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      right: 80px;
      top: 0;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        a {
          color: #000;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
