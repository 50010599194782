<template>
  <div class="canvas">
    <canvas
      :id="id"
      width="160"
      height="160"
      style="width: 124px; height: 124px"
    ></canvas>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
      canvas: "",
      context: "",
      cirX: "",
      cirY: "",
      rad: "",
      n: 1,
      nT: 0,
      speed: 180,
      r: 36,
    };
  },
  props: ["percent", "id", "en"],
  mounted() {
    this.canvas = document.getElementById(this.id);
    this.context = this.canvas.getContext("2d");
    this.context.scale(2, 2);
    this.cirX = 40; //this.canvas.width/ 2
    this.cirY = 40; //this.canvas.height/ 2
    this.rad = (Math.PI * 2) / 210;
    this.DreamLoading();
  },
  created() {},
  methods: {
    //绘制文本
    writeText(n) {
      this.context.save();
      this.context.font = "38px Akrobat";
      this.context.fillStyle = "#179CFF"; //字体颜色
      this.context.fillText(n.toFixed(0), this.cirX - 18, this.cirY + 12); //绘制实心
      this.context.stroke();
      this.context.restore();
    },
    writeTextEn(n) {
      this.context.save();
      this.context.font = "5px Akrobat";
      this.context.fillStyle = "#BBB"; //字体颜色
      this.context.fillText(this.en, this.cirX - 20, this.cirY - 18); //绘制实心
      this.context.stroke();
      this.context.restore();
    },
    //绘制蓝色外圈
    writeBlue(n) {
      this.context.save();
      let grd = this.context.createLinearGradient(0, 160, 0, 0);
      grd.addColorStop(0.65, "#1F98FF"); //这是渐变颜色的添加
      grd.addColorStop(1, "#41EDE2");
      this.context.strokeStyle = grd;
      this.context.lineWidth = 5;
      this.context.lineCap = "round";
      this.context.beginPath();
      this.context.arc(
        this.cirX,
        this.cirY,
        this.r,
        Math.PI / 1.3,
        Math.PI + this.rad * n,
        false
      );
      this.context.stroke();
      this.context.restore();
    },
    DreamLoading() {
      //清除所有，重新绘制
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.writeText(this.n);
      this.writeTextEn();
      this.writeBlue(this.n);
      if (this.n <= this.percent) {
        this.n = this.n + 0.4;
      } else {
        return (this.n = 0);
      }
      requestAnimationFrame(this.DreamLoading);
    },
  },
};
</script>
<style lang="scss" scoped>
.canvas {
  width: 124px;
  height: 124px;
}
</style>
