import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import i18n from '@/lang'
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/all.scss";
import api from "@/api/index";
import mixins from "@/mixins/tuoming";
import VueLazyload from "vue-lazyload";
import loadong from './utils/loading'
import axios from 'axios'
import errorHandler from './utils/errorHandler'

axios.interceptors.response.use(response => {
  response, error => {
    errorHandler(error);
    return Promise.reject(error)
  }
})

Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('@/assets/images/imgPng.png'),
  loading: require('@/assets/images/imgPng.png'),
  attempt: 1
})
Vue.mixin(mixins);
Vue.config.productionTip = false;
Vue.prototype.$Api = api;
// Vue.prototype.$lang = i18n
Vue.use(ElementUI);
Vue.use(loadong)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
