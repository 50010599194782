import { mapState } from "vuex";
export default {
    data() {
        return {
            pages: 0,
            pageIndex: 1,
            upFooter: false,
        };
    },

    computed: {
        ...mapState(['userInfo', "baseInfo", 'isLogin', 'regAuth', 'isOpenRegister', 'isOpenLogin', 'accountMoney', 'payPwd']),
    },
    methods: {
        checkAuthUrl(val) {
            if (!this.payPwd) {
                this.$message({
                    type: 'error',
                    message: '设置提现密码，才可更好的体验',
                })
                this.$router.push('/mine/usersecure')
                return
            }
            if (!this.userInfo.mobile) {
                this.$message({
                    type: 'error',
                    message: '绑定手机号码，才可更好的体验',
                })
                this.$router.push('/mine/usersecure')
                return
            }

            this.$router.push(val)
        },
        closeLoginPop() {
            this.$store.dispatch("openLoginAc", false);
        },
        openLoginPop() {
            this.$store.dispatch("openLoginAc", true);
        },
        closeRegisterPop() {
            this.$store.dispatch("openRegisterAc", false);
        },
        openRegisterPop() {
            this.$store.dispatch("openRegisterAc", true);
        },
        isLoginTip(fun) {
            if (!this.isLogin) {
                this.$confirm("请先登录账号，才能了解更多", "温馨提示", {
                        confirmButtonText: "确定",
                        showCancelButton: false,
                        customClass: "el-pop-wid",
                    })
                    .then(() => {
                        this.$router.push("/user/login");
                    })
                    .catch(() => {
                        console.log(11);
                    });
            } else {
                fun()
            }
        },


        // base接口字段输出内容
        baseText(val) {
            return this.baseInfo.find(item => item.configKey === val).configValue
        },
        contactWeb() {
            window.open(this.baseInfo[6].configValue);
        },
        // KOIpay账号正则
        accountKOI(val) {
            const account = /^[k]{1}[b]{1}[A-Za-z0-9]{15,32}$/;
            return !!account.test(val);
        },
        // EBpay账号正则
        accountEB(val) {
            const account = /^[e]{1}[b]{1}[A-Za-z0-9]{15,32}$/;
            return !!account.test(val);
        },
        // USDT账号正则 -TRC20
        accountUSDTTRC(val) {
            const account = /^[T]{1}[A-Za-z0-9]{15,33}$/;
            return !!account.test(val);
        },
        // USDT账号正则 - ERC20
        accountUSDTERC(val) {
            const account = /^[0]{1}[x]{1}[A-Za-z0-9]{15,40}$/;
            return !!account.test(val);
        },
        // 账号脱敏
        accountShow(val) {
            const obj = val.slice(0, 4) + " **** **** " + val.slice(val.length - 4);
            return obj;
        },
        // 手机号码脱敏
        phoneShow(mobile) {
            let reg = mobile.slice(0, 3) + " **** " + mobile.slice(mobile.length - 4);
            return reg;
        },
        // 邮箱脱敏
        emailHide(email) {
            var avg;
            var splitted;
            var email1;
            var email2;
            splitted = email.split("@");
            email1 = splitted[0];
            avg = email1.length / 2;
            email1 = email1.substring(0, email1.length - avg);
            email2 = splitted[1];
            return email1 + "***@" + email2; // 输出为11223***@qq.com
        },
        // 是否登录跳转
        isJumpLogoUrl(val) {
            if (!this.isLogin) {
                return "/pages/login/login";
            } else {
                return val;
            }
        },
        // 复制
        copyLink(val) {
            let text = val;
            let textarea = document.createElement("textarea");
            textarea.value = text;
            textarea.readOnly = "readOnly";
            document.body.appendChild(textarea);
            textarea.select(); // 选中文本内容
            textarea.setSelectionRange(0, text.length);
            this.$message({
                message: "复制成功",
                type: "success",
            });
            document.execCommand("copy");
            textarea.remove();
        },

        // 金钱加逗号
        amountRule(amount) {
            let defaultAmount = " ";
            let setAmount = amount + " ";
            if (
                setAmount != "null" &&
                setAmount != "" &&
                setAmount != "undefined" &&
                setAmount != "--"
            ) {
                defaultAmount = Number(setAmount)
                    .toString()
                    .replace(/\d(?=(\d{3})+\b)/g, "$&,");
                return defaultAmount;
            } else {
                return defaultAmount;
            }
        },
        getUserBalance(val) {
            if (!this.isLogin) return;
            if (val) {
                this.$loading.show()
            }
            this.$Api.getUserBalance().then((res) => {
                if (res) {
                    this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
                    this.$loading.hide()
                }
            });
        },

        // 是否设置支付密码
        checkIsSetWidthPassword() {
            if (!this.isLogin) return;
            this.$Api.checkIsSetWidthPassword().then((res) => {
                if (res) {
                    const val = res.msg === "yes" ? true : false;
                    this.$store.dispatch("setPayPwdAc", val);
                }
            });
        },
        // 个人信息
        refreshBaseInfo() {
            if (!this.isLogin) {
                localStorage.removeItem("AppToken");
                localStorage.removeItem("userInfo");
                localStorage.removeItem("lang");
                return;
            }
            this.$Api.getUserInfo().then((res) => {
                if (res) {
                    this.$store.dispatch("setUserInfoAc", res.userInfo);
                    // const lang = localStorage.getItem("lang"); //
                    // const isLang = this.langList.find(
                    //     (item) => item.value === res.userInfo.languageType
                    // );
                    // if (isLang) {
                    //     // 设置语言包
                    //     let params = {
                    //         languageType: res.userInfo.languageType,
                    //     };
                    //     this.$i18n.locale = isLang.type;
                    //     localStorage.setItem("lang", isLang.type); // 记录语言关键值
                    //     this.$Api.changeLanguageAndCurrenc(params).then((res) => {
                    //         if (res) {
                    //             if (localStorage.getItem("loginState")) {
                    //                 localStorage.removeItem("loginState");
                    //                 location.reload();
                    //             }
                    //         }
                    //     }); //告知后端语言
                    // }
                }
            });
        },

        // 是否开启短信
        openSms() {
            this.$Api
                .getSysConfigValue({
                 key: "sms_switch_bind",
                })
                .then((res) => {
                    const tagetVal = res.sysConfigValue === "off" ? true : false;
                    this.$store.dispatch("setSmsAc", tagetVal);
                });
        },
    },
};