<template>
    <div class="notice" ref="dom">
        <div class="notice-main">
            <div class="notice-close" @click="close"></div>
            <div class="notice-main-title">
                <h1>NOTICE</h1>
                <p>进入本网站，您承认并确认：</p>
            </div>
            <div class="notice-main-context">
                1、您年满21周岁。<br>
                2、您同意 <strong v-if="baseInfo && baseInfo[5]">{{ baseInfo[5].configValue }}</strong><span
                    @click="jumpweb">的适用条款</span><br>
                <img src="@/assets/images/18.jpeg" class="img" @click="close" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['baseInfo'])
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const dom = this.$refs.dom
            document.body.appendChild(dom)
        },
        close() {
            this.$emit('close')
        },
        jumpweb() {
            this.close()
            this.$router.push('/help?type=ybgz')
        },
    }
}
</script>
<style lang="scss" scoped>
.notice {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, .7);
    z-index: 20;

    &-main {
        position: relative;
        background: white;
        border-radius: 10px;
        padding: 20px;

        &-title {
            text-align: center;
            color: #2b2a2c;

            h1 {
                font-size: 34px;
                font-weight: 800;
                font-style: italic;
            }
        }

        &-context {
            text-align: left;
            color: #2b2a2c;
            padding: 10px 0 0;

            span {
                color: #2599f8;
                cursor: pointer;
            }

            .img {
                cursor: pointer;
            }
        }
    }
}

.notice-close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: url('@/assets/images/close18.png') no-repeat;
    background-size: cover;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
</style>