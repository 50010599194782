<template>
    <div ref="qrcode" class="qrcode"></div>
</template>
  
<script>
import QRCode from 'qrcodejs2';
export default {
    name: 'QRCodeGenerator',
    data() {
        return {
            qrcode: null,
        };
    },
    props: {
        text: {
            type: String,
            required: true,
            default: '',
        },
        width: {
            type: Number,
            default: 200,
        },
        height: {
            type: Number,
            default: 200,
        },
    },
    methods: {
        generateQRCode() {
            if (this.qrcode != null) {
                this.qrcode.clear(); // 清除原来的二维码
            }
            this.qrcode = new QRCode(this.$refs.qrcode, {
                width: this.width,
                height: this.height,
                text: this.text,
            });
        },
    },
    mounted() {
        this.generateQRCode();
    },
};
</script>
  