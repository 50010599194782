<template>
  <div class="yx-wid th-home-bg">
    <div class="yx-wid banner">
      <img
        v-lazy="item.imgUrl"
        v-for="(item, index) in banners"
        :key="index"
        @click="openBanner(item)"
        :class="{ hover: index === bannerInd }"
      />
      <!-- 快捷banner导航 -->
      <div class="banner-nav">
        <span
          v-for="(item, index) in banners"
          :key="index"
          :class="{ hover: index === bannerInd }"
          @click="tabBanner(item, index)"
        ></span>
      </div>
      <!-- 左右切换 -->
      <div class="banner-prev" @click="tabPrev"></div>
      <div class="banner-next" @click="tabNext"></div>
    </div>
    <!-- 跑马灯 -->
    <div class="yx-wid th-run-bg" v-if="newsList && newsList.length">
      <div class="yx-wid-12 home-ad">
        <div class="home-ad-icon icon-msg"></div>
        <div class="home-ad-scroll" ref="scorll">
          <div
            class="home-ad-text"
            @mouseout="scrollStar"
            @mouseover="moveScroll"
            ref="scorllWid"
            :style="{
              transform: 'translateX(' + scorllLeftVal + 'px)',
              transition: scrollLeftStar,
            }"
          >
            <div
              class="row"
              v-for="(item, index) in newsList"
              @click="openMessage(item)"
              :key="index"
              v-html="item.scrollContent"
            ></div>
          </div>
        </div>
        <div
          class="home-ad-more icon-arrow-r"
          @click="isLoginTip(viewMessage)"
        ></div>
      </div>
    </div>
    <!-- 马德里竞技俱乐部ad -->
    <div
      v-if="baseInfo && baseInfo[40].configKey"
      class="yx-wid-12 home-ad-img"
      @click="$router.push('/sportIntroduce')"
    >
      <div class="row"><img src="@/assets/pic/copor-1.png" /></div>
      <div class="row"><img src="@/assets/pic/copor-2.png" /></div>
      <div class="row"><img src="@/assets/pic/copor-3.png" /></div>
    </div>
    <!-- 热门游戏 -->
    <div class="yx-wid-12" v-if="hotsGameList">
      <div class="home-title">热门游戏 任您畅游</div>
      <div class="th-game-live">
        <ul>
          <li
            v-for="(item, index) in hotsGameList[0].list.slice(0, 3)"
            :key="index"
          >
            <div class="info">
              <div class="left">{{ item.apiTitle }}</div>
              <div class="right">{{ item.platType }}</div>
            </div>
            <div class="photo">
              <img v-lazy="item.imageUrl" />
            </div>
            <div class="room">
              <div class="left">
                {{
                  item.gameDescription ? item.gameDescription : "暂无介绍信息"
                }}
              </div>
              <div class="right" @click="openGame(item)">立即游戏</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="th-game-dz" v-if="records">
        <ul>
          <li v-for="(item, index) in records" :key="index">
            <div class="left">
              <img v-lazy="item.gameImgUrl" />
            </div>
            <div class="right">
              <p>{{ item.gameName }}</p>
              <div class="link" @click="openGame(item)">立即游戏</div>
            </div>
            <div class="type">{{ item.platType }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="yx-wid th-index-bg">
      <!-- 活动 -->
      <div class="yx-wid-12 home-load" v-if="activityList">
        <div class="home-title text-ak">超值优惠 任你来领</div>
        <div class="th-activit">
          <ul>
            <li
              v-for="(item, index) in activityList"
              :key="index"
              @click="jumpWeb(item)"
            >
              <div class="sec">
                <h3>{{ item.activityName }}</h3>
                <p>活动截止日期：{{ item.time }}</p>
                <div class="link">查看详情></div>
              </div>
              <div
                class="bg"
                :style="{ backgroundImage: 'url(' + item.bannerImgUrl + ')' }"
              ></div>
            </li>
          </ul>
        </div>
      </div>
      <!-- APP下载 -->
      <div class="yx-wid-12 home-load">
        <div class="home-title text-ak">下载APP 随身娱乐</div>
        <div class="home-load-flex">
          <div class="flex-left">
            <!-- <img
            v-if="baseInfo && baseInfo[41].configKey"
            :src="baseText('webAppLeftImg')"
            class="img"
          /> -->
            <img src="@/assets/pic/download-app.png" class="img" />
          </div>
          <div class="flex-right" v-if="baseInfo && baseInfo[22]">
            <div class="load-tab">
              <ul>
                <li
                  v-for="(item, index) in loads"
                  :key="index"
                  @click="loadTab(item, index)"
                  :class="
                    index === loadInd ? 'btn-default-theme' : 'btn-default'
                  "
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="load-sec">
              <strong>掌上娱乐 指尖投注 </strong>
              <p>
                全球首家一体化娱乐原生APP，尽显流畅，完美操作。海量体育、电竞赛事更有真人娱乐、棋牌游戏、彩票投注、电子游艺等娱乐项目。扫码下载，即刻掌上投注，体验极致流畅APP！
              </p>
            </div>
            <div class="load-add" v-show="[0].includes(loadInd)">
              <div class="load-add-code">
                <div class="main">
                  <QRCode
                    :text="baseText('iosApp')"
                    :width="140"
                    :height="140"
                  />
                </div>
                <div class="text">
                  <span>{{ $t("download.sm") }}</span>
                  <p>{{ $t("download.zc") }}iOS</p>
                </div>
              </div>
              <div class="load-add-web">
                <div class="main add">
                  <a :href="baseText('iosApp')">{{ baseText("iosApp") }}</a>
                </div>
                <div class="text">
                  <span>{{ $t("download.zjfw") }}</span>
                  <p>{{ $t("download.zjfw-tips") }}</p>
                </div>
              </div>
            </div>
            <div
              class="load-add"
              v-show="[1].includes(loadInd) && baseInfo && baseInfo[21]"
            >
              <div class="load-add-code">
                <div class="main">
                  <QRCode
                    :text="baseText('androidApp')"
                    :width="140"
                    :height="140"
                  />
                </div>
                <div class="text">
                  <span>{{ $t("download.sm") }}</span>
                  <p>{{ $t("download.zc") }}andriod</p>
                </div>
              </div>
              <div class="load-add-web">
                <div class="main add">
                  <a :href="baseText('androidApp')">{{
                    baseText("androidApp")
                  }}</a>
                </div>
                <div class="text">
                  <span>{{ $t("download.zjfw") }}</span>
                  <p>{{ $t("download.zjfw-tips") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Notice v-if="notice" @close="notice = false" />
    <!-- <div class="yx-wid th-index-ad" v-if="isAd">
      <div class="yx-wid-12">
        <div class="icon-close close" @click="isAd = false"></div>
        <img src="@/assets/pic/bear-left.png" class="th-ad-kt-left" />
        <img
          src="@/assets/pic/text1.png"
          @click="$router.push('/sportIntroduce')"
        />
        <img src="@/assets/pic/bear-right.png" class="th-ad-kt-right" />
      </div>
    </div> -->
  </div>
</template>

<script>
import Notice from "@/views/notice.vue";
import CanvasCircle from "@/components/Canvas.vue";
import QRCode from "@/components/QRCode.vue";
import { mapState } from "vuex";
export default {
  components: { CanvasCircle, QRCode, Notice },
  name: "Home",
  data() {
    return {
      canvasList: [
        {
          ratio: 60,
          name: this.$i18n.t("yzfw.pjcksj"),
          en: "AVERAGE TIME OF DEPOSIT",
          unit: this.$i18n.t("yzfw.m"),
          sec: "CURRENT SPEED",
        },
        {
          ratio: 80,
          name: this.$i18n.t("yzfw.hzzfpt"),
          en: "PAYMENT PLATFORM PARTNERS",
          unit: this.$i18n.t("yzfw.j"),
          sec: "TOTALLY AMOUNT",
        },
        {
          ratio: 90,
          name: this.$i18n.t("yzfw.pjqksj"),
          en: "AVERAGE TIME OF WITHDRAW",
          unit: this.$i18n.t("yzfw.m"),
          sec: "CURRENT SPEED",
        },
        {
          ratio: 32,
          name: this.$i18n.t("yzfw.hzyxpt"),
          en: "GAMING PROVIDER PARTNERS",
          unit: this.$i18n.t("yzfw.j"),
          sec: "TOTALLY AMOUNT",
        },
      ],
      bannerInd: 0,
      bannerTimer: null,
      loads: [
        {
          name: "IOS APP",
          title: "iOS APP",
          type: 1,
          sec: "全球首家一体化娱乐原生APP ，尽显流畅，完美操作。海量体育，电竞顶尖赛事，真人娱乐，彩票投注及电子游艺等，最新最全娱乐项目尽在掌中体验扫码下载，即刻拥有！",
          img: require("@/assets/images/ad01.png"),
          link: "",
        },
        {
          name: "Andriod APP",
          title: "andriod APP",
          type: 2,
          sec: "业内赔率最高！覆盖世界各地赛事，让球、大小、半全场、波胆、单双、总入球、连串过关等多元竞猜。更有动画直播，让您体验轻松聊球，娱乐投注两不误。",
          img: require("@/assets/images/ad02.png"),
          link: "",
        },
      ],
      loadInd: 0,
      liveTabs: [
        { name: "真人主播", type: "" },
        { name: "足球赛事", type: "" },
        { name: "篮球赛事", type: "" },
        { name: "电竞赛事", type: "" },
      ],
      liveInd: 0,
      hotsGameInd: 0,
      newsList: null,
      banners: null,
      canvas: null,
      context: null,
      flash: 0.1,
      scorllLeftVal: 0,
      scrollLeftStar: "all .4s",
      timerText: null,
      scorllWid: 0,
      isScroll: false,
      notice: false,
      isAd: true,
      records: null,
      activityList: null,
    };
  },
  computed: {
    ...mapState([
      "navsVal",
      "navsList",
      "isLogin",
      "baseInfo",
      "hotsGameList",
      "userInfo",
    ]),
  },
  watch: {
    navsVal(val) {
      if (!val) return;
      if (val) {
        this.initGameList();
      }
    },
    baseInfo(val) {
      if (val && val.length) {
        this.initBaseInfo();
      }
    },
    userInfo(val) {
      if (val) {
        this.checkName();
      }
    },
  },
  mounted() {
    this.getTop50MessageList();
    this.getSilde();
    // this.isShow21()
    this.checkName();
    this.getGameListByGameType();
    this.getActivityList();
    this.initGameList();
  },
  methods: {
    // 打开详情页面
    jumpWeb(val) {
      this.$router.push({
        path: "/activity/activityLandPage",
        query: {
          type: val.id,
        },
      });
    },
    getActivityList() {
      const params = {
        device: 1,
        pageIndex: 1,
        pageSize: 3,
      };
      this.$Api.getActivityList(params).then((res) => {
        if (res) {
          res.activityList.records.forEach((item) => {
            const date = new Date(item.endTime);
            const year = date.getFullYear();
            if (year === 9999) {
              item.time = "长期有效";
            } else {
              item.time = item.endTime;
            }
          });
          this.activityList = res.activityList.records.slice(0, 3);
        }
      });
    },
    getGameListByGameType() {
      const params = {
        pageIndex: 1,
        gameType: 2,
        pageSize: 4,
      };
      this.$Api.getGameListByGameType(params).then((res) => {
        if (res) {
          this.records = res.page.records;
          console.log(res);
        }
      });
    },
    // 检测是否设置姓名
    checkName() {
      if (this.isLogin && this.userInfo && !this.userInfo.realName) {
        this.$confirm(
          "检测到您还未设置姓名,请先去设置,方便更好的体验～",
          "温馨提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "none",
          }
        )
          .then(() => {
            this.$router.push("/mine/userInfo");
          })
          .catch(() => {
            console.log("取消");
          });
      }
    },
    // 是否显示21周岁
    isShow21() {
      const dayOld = localStorage.getItem("notice");
      const time = Date.parse(new Date()); //获取当天时间
      const TimeDays = 1000 * 60 * 60 * 24; //设定1天时间
      const isDayTime = time - Number(dayOld); //计算时间
      if (dayOld) {
        this.notice = false;
        if (isDayTime >= TimeDays) {
          localStorage.removeItem("notice", time);
          this.notice = true;
        }
      } else {
        localStorage.setItem("notice", time);
        this.notice = true;
      }
    },

    // 基础信息赋值给data上
    initBaseInfo() {
      if (!this.baseInfo) return;
      this.loads.forEach((item) => {
        if (item.type === 1) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "androidApp"
          ).configValue;
        }
        if (item.type === 2) {
          item.link = this.baseInfo.find(
            (item) => item.configKey === "iosApp"
          ).configValue;
        }
      });
    },
    initGameList() {
      if (!this.navsList) return;
      const arr = this.navsList.filter(
        (item) => !["0"].includes(item.gameType) && item.gameType
      );
      if (arr) {
        setTimeout(() => {
          this.$store.dispatch("setHotsGameListAc", arr);
        }, 500);
      }
    },
    viewMessage() {
      this.$router.push("/mail");
    },
    openMessage() {
      this.$router.push("/message");
    },
    // banner
    getSilde() {
      const params = {
        position: 1,
        device: 1,
      };
      this.$Api.getSilde(params).then((res) => {
        if (res) {
          this.banners = res.slideList;
          this.autoBanner();
        }
      });
    },
    // 公告
    getTop50MessageList() {
      this.$Api.getTop50MessageList().then((res) => {
        if (res) {
          this.newsList = res.messageList;
          if (this.newsList && this.newsList.length) {
            this.$nextTick(() => {
              this.scrollInit();
            });
          }
        }
      });
    },
    moveScroll() {
      clearInterval(this.timerText);
    },
    // 跑马灯初始化
    scrollInit() {
      // 获取box宽度
      const dowSocrll = this.$refs.scorll.clientWidth;
      this.scorllWid = this.$refs.scorllWid.clientWidth;
      const domClone = this.$refs.scorllWid;
      this.isScroll = this.scorllWid > dowSocrll ? true : false;
      if (this.isScroll) {
        let dom = domClone.cloneNode(true);
        domClone.appendChild(dom);
        this.scrollStar();
      }
    },
    scrollStar() {
      if (!this.isScroll) return;
      this.timerText = setInterval(() => {
        const valNumber =
          this.scorllWid - this.scorllLeftVal < 10
            ? this.scorllWid - this.scorllLeftVal
            : 5;
        this.scorllLeftVal -= valNumber;
        if (this.scorllLeftVal <= -this.scorllWid) {
          this.scorllLeftVal = 0;
          this.scrollLeftStar = "none";
          setTimeout(() => {
            this.scrollLeftStar = "all .4s";
          }, 50);
        }
      }, 120);
    },
    tabLive(val, index) {
      this.liveInd = index;
    },
    TabHotsGame(val, index) {
      this.hotsGameInd = index;
    },
    autoBanner() {
      this.bannerTimer = setInterval(() => {
        this.bannerInd++;
        if (this.bannerInd >= this.banners.length) {
          this.bannerInd = 0;
        }
      }, 5000);
    },
    tabBanner(val, index) {
      clearInterval(this.bannerTimer);
      this.bannerInd = index;
      this.autoBanner();
    },
    openBanner(val) {
      if (val.activityId != null) {
        this.$router.push("/activity/activityLandPage?type=" + val.activityId);
      }
    },
    tabPrev() {
      clearInterval(this.bannerTimer);
      if (this.bannerInd === 0) {
        this.bannerInd = this.banners.length;
      }
      this.bannerInd--;
      this.autoBanner();
    },
    tabNext() {
      clearInterval(this.bannerTimer);
      this.bannerInd++;
      if (this.bannerInd === this.banners.length) {
        this.bannerInd = 0;
      }
      this.autoBanner();
    },
    loadTab(val, index) {
      this.loadInd = index;
    },
    // 打开游戏
    openGame(val) {
      if (val.gameType === 2) {
        this.$router.push({
          path: "/game/egame",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (val.gameType === 6) {
        this.$router.push({
          path: "/game/fishing",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (!this.isLogin) {
        this.$confirm("请先登录账号，才能了解更多", "温馨提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
          customClass: "el-pop-wid",
        })
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }

      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.moveScroll);
  },
};
</script>

<style lang="scss" scoped>
.banner {
  height: 480px;
  position: relative;
  overflow: hidden;

  &:hover {
    .banner-next,
    .banner-prev {
      display: block;
    }
  }

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(0.85);
    top: 0;
    height: 100%;
    opacity: 0;
    transition: all 0.8s;

    &.hover {
      opacity: 1;
      transform: translateX(-50%) scale(1);
      z-index: 1;
    }
  }

  &-nav {
    white-space: nowrap;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    span {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin: 0 3px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
      }

      &::before {
        width: 50%;
        height: 50%;
        background: rgba(100, 100, 100, 0.5);
        display: block;
      }

      &.hover {
        &::before {
          background: var(--theme);
        }

        &::after {
          width: 70%;
          height: 70%;
          border: 1px solid var(--theme);
        }
      }
    }
  }
}

.banner-prev,
.banner-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 80px;
  z-index: 2;
  cursor: pointer;
  left: 5px;

  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid transparent;
    border-left-color: var(--theme);
    border-top-color: var(--theme);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.banner-next {
  right: 10px;
  left: auto;

  &::before {
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

.home-ad {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 20px;
  padding: 0 10px 0 18px;
  gap: 10px;
  &-icon {
    font-size: 20px;
    color: white;
  }

  &-scroll {
    flex: 1;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    color: white;
    position: relative;
  }

  &-text {
    display: inline-block;
    white-space: nowrap;
    // width: 100%;
    // animation: amin 10s linear infinite;

    .row {
      display: inline-block;
      vertical-align: middle;
      margin-right: 60px;
      cursor: pointer;
    }
  }

  &-more {
    color: white;
    font-size: 12px;
  }

  &-img {
    margin-top: 50px;
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    .row {
      flex: 1;
      overflow: hidden;
    }

    img {
      transition: all 0.4s;
      width: 100%;

      &:hover {
        transform: scale(1.04);
      }
    }
  }
}

// @keyframes amin {
//   0% {
//     transform: translateX(0);
//   }

//   100% {
//     transform: translateX(-100%);
//   }
// }

.home-title {
  width: 100%;
  margin: 88px 0 32px;
  padding: 0 0 24px;
  background: url("@/assets/pic/index-title-bg.png") no-repeat center center;
  background-size: 80% auto;
  text-align: center;
  font-size: 32px;
  letter-spacing: 3px;
  font-weight: bold;
  text-shadow: 0 2px 0 white;
}

.home-load-flex {
  display: flex;
  align-items: center;
  gap: 80px;

  .img {
    width: 880px;
  }

  .flex-left {
    flex: 1;
    max-width: 600px;

    .img {
      width: 100%;
    }
  }

  .flex-right {
    flex: 1;
    .load-tab {
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      li {
        width: 132px;
        height: 44px;
      }
    }

    .load-sec {
      padding: 0 10px;

      strong {
        font-size: 40px;
        margin-top: 32px;
        display: block;
      }

      p {
        line-height: 28px;
        font-size: 18px;
        margin-top: 12px;
        text-align: justify;
      }
    }

    .load-add {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 10px 0;
      width: 100%;
      gap: 40px;

      &-code,
      &-web {
        border: 1px solid #000;
        border-radius: 12px;
        width: 200px;
        height: 260px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .main {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .text {
          text-align: center;
          background: #444444;
          color: white;
          width: 100%;
          padding: 10px 0;

          span {
            font-size: 16px;
            display: block;
          }

          p {
            font-size: 12px;
            margin-top: 2px;
            white-space: nowrap;
          }
        }
      }

      &-web {
        .add {
          width: 120px;
          height: 120px;

          a {
            color: #000;
            word-wrap: break-word;
          }
        }
      }

      &-code {
        .img {
          width: 120px;
        }
      }
    }
  }
}

.load-add-pc {
  padding: 0 10px;
  margin-top: 32px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li {
    width: 120px;
    height: 168px;
    background: #f0f6ff;
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 60%;
    }

    .btn {
      background: url("@/assets/images/tab-bg1.png") no-repeat;
      line-height: 22px;
      height: 22px;
      width: 68px;
      font-size: 12px;
      background-size: 100% 100%;
      cursor: pointer;
      box-shadow: 0 2px 5px #5da7eb;
      border-radius: 11px;
      color: white;
      text-align: center;
      margin-top: 12px;
    }
  }
}

.home-live-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .live-row {
    min-width: 120px;
    height: 40px;
    cursor: pointer;
  }
}

.live-box {
  background: url("@/assets/images/live-bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 6px 20px rgba(201, 211, 237, 0.5);
  border-radius: 24px;
  padding: 24px;
  margin-top: 24px;
}

.live-now {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    flex: 1;
    overflow: hidden;

    dl {
      display: flex;
      align-items: center;

      dd {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 108px;
        height: 40px;
        padding: 8px 12px;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(210, 183, 156, 0.15);
        background: linear-gradient(
            0deg,
            hsla(30, 37%, 72%, 0.1),
            hsla(30, 37%, 72%, 0.1)
          ),
          #fff;
        background: #e9f5fe;
        border: 1px solid #2599f8;
        white-space: nowrap;
        box-shadow: 0 4px 8px rgba(23, 156, 255, 0.15);

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .right {
    position: relative;

    input {
      width: 156px;
      height: 33px;
      border-radius: 12px;
      background-image: url("@/assets/images/glass.png");
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 12px;
      border: 1px solid transparent;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 600;
      padding: 8px 12px 8px 36px;
      transition: all 0.3s;
      z-index: 1;
      color: #48535e;
      background-color: #fff;

      &::placeholder {
        color: #c9d1d8 !important;
      }
    }
  }
}

.live-scroll {
  margin-top: 16px;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 144px;

  &-list {
    white-space: nowrap;
    width: 100%;
  }

  &-box {
    display: inline-block;
    vertical-align: middle;
    width: 320px;
    height: 112px;
    margin-right: 16px;
    filter: drop-shadow(0 8px 20px rgba(204, 221, 219, 0.4));
  }

  &-row {
    width: 320px;
    height: 112px;
    box-sizing: border-box;
    cursor: pointer;
    background: #e9eff8;
    border-radius: 12px;
    background: #fff;
    border: 2px solid #fff;
    padding: 44px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      border: 2px solid #2599f8;
    }

    &.hover {
      background: #f0f6ff;
      border: 2px solid #2599f8;

      &::before {
        content: "";
        position: absolute;
        border: 8px solid transparent;
        border-top-color: #2599f8;
        border-bottom: none;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .live-row-title {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      height: 20px;
      line-height: 20px;
      font-weight: 500;
      justify-content: center;
      padding: 0;
      margin-bottom: 8px;
      color: #30383f;
    }

    .left,
    .right {
      text-align: center;

      .img {
        flex: 0 0 44px;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        background: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

        img {
          width: 84%;
        }
      }
    }

    .center {
      padding: 0 30px;
      text-align: center;

      .vs {
        display: flex;
        height: 24px;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 5px;
        text-overflow: -5px;
      }

      .text {
        font-size: 12px;
      }

      .vs-open {
        display: flex;
        align-items: center;
        justify-content: center;

        .vs-row {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 24px;
          height: 24px;
          margin: 0 3px;

          &.one {
            background-image: url("@/assets/images/game-icon-01.png");
          }

          &.two {
            background-image: url("@/assets/images/game-icon-03.png");
          }

          &:hover {
            &.one {
              background-image: url("@/assets/images/game-icon-02.png");
            }

            &.two {
              background-image: url("@/assets/images/game-icon-04.png");
            }
          }
        }
      }
    }
  }
}

.live-video {
  display: flex;
  align-items: center;

  &-left {
    height: 650px;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 24px;
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: white;
    padding: 12px 0;

    .name {
      color: #30383f;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 6px solid #d3ebfe;
        background: white;
        border-radius: 100%;
        margin: 0 8px;
      }
    }

    .vs-number {
      font-size: 36px;
      letter-spacing: 5px;
      color: #30383f;
      font-weight: bold;
      margin: 0 10px;
    }
  }

  &-right {
    width: 308px;
    display: flex;
    flex-direction: column;
    height: 650px;
    background: #f1f3f5;
    border-radius: 12px;
    border: 1px solid #fff;

    .chat-list {
      flex: 1;
      position: relative;
      overflow: hidden;

      &-no {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .chat-send {
      background: white;
      display: flex;
      align-items: center;
      padding: 12px;

      .send {
        background: #f1f3f5;
        border: 1.5px solid #fff;
        height: 36px;
        line-height: 1;
        border-radius: 12px;
        background-image: url("@/assets/images/fj.png");
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 250px center;
        padding: 8px 36px 8px 8px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }
    }
  }
}

.th-run-bg {
  background: rgba(164, 114, 34, 0.7);
}
.th-home-bg {
  background: url("@/assets/pic/index-bg.png");
  background-repeat: repeat-y;
  background-position: top center;
  background-size: contain;
}
.th-index-ad {
  background: #212121;
  position: sticky;
  bottom: 0;
  z-index: 20;
  .yx-wid-12 {
    padding: 30px 0;
    position: relative;
  }
  .close {
    position: absolute;
    background: var(--theme);
    color: white;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-120%);
    font-weight: bold;
    cursor: pointer;
  }
  .th-ad-kt-left,
  .th-ad-kt-right {
    position: absolute;
    bottom: 10px;
  }
  .th-ad-kt-left {
    transform: translateX(-100%);
  }
  .th-ad-kt-right {
    right: 0;
    transform: translateX(100%);
  }
}
.th-game-live {
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  li {
    background: url("@/assets/pic/luckway-new-bg.png") no-repeat center center;
    box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.2);
    border: 2px solid white;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    .info,
    .room {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .info {
      .left {
        color: var(--theme);
        font-size: 16px;
      }
      .right {
        color: var(--theme);
      }
    }
    .room {
      .left {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        white-space: nowrap;
      }
      .right {
        background: linear-gradient(180deg, #b48a5c 0%, #ddc89f 100%);
        border: 1px solid #ffffff;
        box-shadow: 0px -4px 6px #ffffff, 0px 2px 0px rgba(255, 255, 255, 0.4),
          0px 4px 8px rgba(0, 0, 0, 0.2),
          inset 0px 2px 4px rgba(200, 108, 0, 0.3);
        border-radius: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(117, 97, 67, 0.3);
        padding: 0 20px;
        cursor: pointer;
      }
    }
    .photo {
      width: 100%;
      height: 132px;
      background: #f2e9d5;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
      }
    }
  }
}
.th-game-dz {
  margin-top: 20px;
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  li {
    background: url("@/assets/pic/luckway-new-bg.png") no-repeat center center;
    box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.2);
    border: 2px solid white;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: hidden;
    position: relative;
    .left {
      width: 88px;
      height: 88px;
      background: #f2e9d5;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      p {
        font-size: 16px;
      }
    }
    .link {
      background: linear-gradient(180deg, #b48a5c 0%, #ddc89f 100%);
      border: 1px solid #ffffff;
      box-shadow: 0px -4px 6px #ffffff, 0px 2px 0px rgba(255, 255, 255, 0.4),
        0px 4px 8px rgba(0, 0, 0, 0.2), inset 0px 2px 4px rgba(200, 108, 0, 0.3);
      border-radius: 24px;
      font-weight: 600;
      font-size: 13px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 1px 1px rgba(117, 97, 67, 0.3);
      padding: 0 10px;
      cursor: pointer;
    }
    .type {
      padding: 2px 2px 2px 5px;
      border-radius: 10px 10px 0px 10px;
      gap: 2px;
      text-align: center;
      color: #333333;
      font-size: 14px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      background: #f0e3ca;
      line-height: 24px;
      font-size: 12px;
    }
  }
}
.th-activit {
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 340px;
    padding-top: 30px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.2);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    .bg {
      background-size: auto 70%;
      background-position: 18% 130%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      filter: grayscale(60%);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 80%;
        background: linear-gradient(
          180deg,
          white 50%,
          rgba(255, 255, 255, 0.1)
        );
      }
    }
    .sec {
      position: relative;
      z-index: 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-size: 24px;
        color: var(--theme);
      }
      p {
        color: #999;
      }
      .link {
        cursor: pointer;
        text-decoration: underline;
        font-size: 16px;
      }
    }
  }
}
.th-index-bg {
  background: url("@/assets/pic/index-bg1.png") no-repeat center top;
  background-size: contain;
  overflow: hidden;
  padding-top: 50px;
  margin-top: 30px;
}
</style>
