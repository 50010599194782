<template>
  <div class="yx-wid th-header">
    <div class="yx-wid-12 th-header-box">
      <div class="th-header-left" v-if="baseInfo">
        <img
          :src="baseInfo[16].configValue"
          class="logo"
          @click="$router.push('/')"
        />
        <span class="line"></span>
        <span class="name">{{ menuTarget.name }}</span>
      </div>
      <div class="th-header-right">
        <template v-if="menulist">
          <span
            class="link"
            v-for="(item, index) in menulist"
            :key="index"
            @click="jumpWeb(item)"
          >
            {{ item.name }}
          </span>
        </template>
        <div class="th-userInfo" v-if="userInfo">
          <img :src="userInfo.photoUrl" class="photo" />
          <div class="main">
            <span>{{ userInfo.userName }}</span>
            <p>
              {{
                accountMoney.userBalance
                  ? accountMoney.userBalance.toFixed(2)
                  : "0.00"
              }}
            </p>
          </div>
        </div>
        <span class="icon icon-email" @click="$router.push('/mail')"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menus: [
        {
          name: "充值",
          type: "deposit",
          link: "/finance/deposit",
        },
        {
          name: "转账",
          type: "transfer",
          link: "/finance/transfer",
        },
        {
          name: "提现",
          type: "withdrawal",
          link: "/finance/withdrawal",
        },
      ],
    };
  },
  computed: {
    menulist() {
      const list = this.menus.filter(
        (item) => item.type !== this.$route.meta.type
      );
      return list;
    },
    menuTarget() {
      const list = this.menus.find(
        (item) => item.type === this.$route.meta.type
      );
      return list;
    },
  },
  mounted() {},
  methods: {
    jumpWeb(val) {
      if (val.type !== "transfer") {
        this.checkAuthUrl(val.link);
      } else {
        this.$router.push(val.link);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.th-header {
  background: #0b0b0c;
  height: 90px;
  display: flex;
  align-items: center;
  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-left {
    display: flex;
    align-items: center;
    gap: 20px;
    .logo {
      height: 54px;
    }
    .line {
      height: 48px;
      width: 1px;
      background: var(--theme);
      opacity: 0.4;
    }
    .name {
      font-size: 32px;
      color: var(--theme);
    }
  }
  &-right {
    display: flex;
    align-items: center;
    gap: 10px;
    .link {
      font-size: 15px;
      color: #999;
      border-radius: 20px;
      border: 1px solid var(--theme);
      display: flex;
      align-items: center;
      padding: 3px 16px;
      cursor: pointer;
    }
    .photo {
      width: 48px;
    }
    .icon {
      color: var(--theme);
      font-size: 24px;
      margin-left: 30px;
      opacity: 0.8;
    }
  }
}
.th-userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  span {
    color: #999;
  }
  p {
    color: var(--theme);
  }
  .main {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
</style>
