<template>
    <div class="loading" v-if="visible">
        <img src="@/assets/images/load.gif" class="img" />
        <!-- <p>加载中</p> -->
    </div>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
.loading {
    position: fixed;
    z-index: 20;
    background: rgba(10, 24, 35, .6);
    border-radius: 10px;
    width: 64px;
    height: 64px;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);

    img {
        width: 24px;
        height: 24px;
        margin: 0 auto;
    }

    // p {
    //     font-size: 12px;
    //     margin-top: 6px;
    //     color: rgba(255, 255, 255, .7);
    // }
}
</style>