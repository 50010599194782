import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang_zh from '@/lang/zh.json'
import lang_hk from '@/lang/hk.json'
import lang_en from '@/lang/en.json'
import lang_th from '@/lang/th.json'
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh',
    messages: {
        zh: {...lang_zh },
        hk: {...lang_hk },
        en: {...lang_en },
        th: {...lang_th },
    }
})
export default i18n