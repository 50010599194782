import Vue from 'vue'
import componentLoading from '@/components/Loading'

const comLoading = Vue.extend(componentLoading)

const instance = new comLoading({
    el: document.createElement('div')
})

instance.visible = false
const loading = {
    show() {
        instance.visible = true
        document.body.appendChild(instance.$el)
    },
    hide() {
        instance.visible = false
    },
}

export default {
    install() {
        if (!Vue.$loading) {
            Vue.$loading = loading
        }
        Vue.mixin({
            created() {
                this.$loading = Vue.$loading
            },
        })
    },
}