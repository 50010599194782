<template>
  <div id="app">
    <Header v-if="isHeader === 0" />
    <HeaderT v-if="isHeader === 1" />
    <HeaderAgen v-if="isHeader === 2" />
    <router-view />
    <Footer v-if="isFooter" />
    <Register v-if="isOpenRegister" />
    <Login v-if="isOpenLogin" />
    <Contact />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import HeaderT from "@/components/HeaderT.vue";
import HeaderAgen from "@/components/HeaderAgen.vue";
import Footer from "@/components/Footer.vue";
import Register from "@/components/Register.vue";
import Login from "@/components/Login.vue";
import Contact from "@/components/Contact.vue";
import { mapState } from "vuex";
export default {
  components: { HeaderT, Header, HeaderAgen, Footer, Register, Login, Contact },
  data() {
    return {
      isHeader: 0,
      isFooter: true,
      isError: false,
      Handler: null,
      beforeUNload: null,
    };
  },
  watch: {
    $route(val) {
      this.infoRoute(val.meta);
    },
    isLogin(val) {
      if (val) {
        this.getUserInfo();
        this.checkIsSetWidthPassword();
        this.getUserBalance();
      }
    },
  },
  mounted() {
    this.infoRoute(this.$route.meta);
    this.getWebsiteBasicInfo();
    this.getUserInfo();
    this.getRegisterItem();
    this.initInfo();
    this.checkIsSetWidthPassword();
  },
  computed: {
    ...mapState(["languages"]),
  },
  methods: {
    initInfo() {
      if (this.$route.query.invitationCode) {
        this.openRegisterPop();
      }
    },
    handunload(e) {
      this.Handler = new Date().getTime() - this.beforeUNload;
      if (this.Handler <= 5) {
        localStorage.removeItem("AppToken");
        this.$store.dispatch("setHasLoginAc", false);
        this.$store.dispatch("setUserInfoAc", null);
      }
    },
    getRegisterItem() {
      this.$Api.getRegisterItem().then((res) => {
        if (res) {
          this.$store.dispatch("setRegAuthAc", res.registerItemList[0].isShow);
          this.$store.dispatch("setRegisterInfoAc", res.registerItemList);
        }
      });
    },
    // 个人资料
    getUserInfo() {
      const isLogin = localStorage.getItem("AppToken");
      if (!isLogin) {
        localStorage.removeItem("AppToken");
        localStorage.removeItem("userInfo");
        return;
      }
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
          if (res.userInfo.languageType) {
            this.changeLanguageAndCurrenc(res.userInfo.languageType, "lang");
          }

          if (res.userInfo.currencyType) {
            this.changeLanguageAndCurrenc(res.userInfo.currencyType, "area");
          }
        }
      });
    },
    changeLanguageAndCurrenc(val, type) {
      let params = {};
      if (type === "lang") {
        params.languageType = val;
      }
      if (type === "area") {
        params.currencyType = val;
      }
      this.$Api.changeLanguageAndCurrenc(params).then((res) => {
        if (res) {
          if (type === "lang") {
            let langtarget = this.languages.find(
              (item) => item.id === val
            ).type;
            this.$i18n.locale = langtarget;
            localStorage.setItem("lang", langtarget);
          } else {
            localStorage.setItem("area", val);
          }
        }
      });
    },
    // 是否开启短信
    openSms() {
      this.$Api
        .getSysConfigValue({
          key: "sms_switch",
        })
        .then((res) => {
          const tagetVal = res.sysConfigValue === "off" ? true : false;
          this.$store.dispatch("setSmsAc", tagetVal);
        });
    },
    infoRoute(val) {
      const { head, footer } = val;
      const isHead = head ? head : 0;
      this.isHeader = isHead;
      if (!footer) {
        this.isFooter = true;
      } else {
        this.isFooter = false;
      }
    },
    getWebsiteBasicInfo() {
      this.$Api.getWebsiteBasicInfo().then((res) => {
        const baseInfo = res.websiteBasicList;
        this.$store.dispatch("setBaseInfoAC", baseInfo);
        this.exitIco(baseInfo);
        this.setTitle(baseInfo);
      });
    },
    setTitle(val) {
      document.title = val.find(
        (item) => item.configKey === "title"
      ).configValue;
    },
    exitIco(val) {
      const ico = document.querySelector('link[rel="icon"]');
      const title = document.querySelector("title");
      title.innerHTML = val[0].configValue;
      if (ico !== null) {
        ico.href = val[1].configValue;
      } else {
        ico = document.createElement("link");
        ico.rel = "icon";
        ico.href = val[1].configValue;
        document.head.appendChild(ico);
      }
    },
  },
};
</script>
